import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import {
  Container,
  //Title,
  Content,
  Button,
  //Grid,
  Image,
  A
} from '../components/styled'

const AuthorImage = styled(Image).attrs(props => ({
  className: ``
}))`
  border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
`

const Author = (props) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "sascha-klapetz-2019-profile.jpg" }) {
        ...squareImage
      }
    }
  `)
  return (
  <div className="cf">
    <div className="fr mb3 mb0-l ml4 w-100 w-auto-ns">
      <A as={Link} to="/kontakt/"><AuthorImage as={Img} className="center w4" fluid={data.image.childImageSharp.fluid} /></A>
    </div>
    <div className="overflow-hidden">
      <Content full-width>
        <p>Mein Name ist <A colored as={Link} to="/kontakt/"><strong>Sascha Klapetz</strong></A> und ich mache Website-Konzepte, UX/SEO-Audits, Landingpages, Pagespeed-Optimierung, Leadgenerierung und Webentwicklung. Immer mit dem Ziel: Potenzielle Kunden für Ihr Unternehmen begeistern. Machen Sie jetzt Ihr Online-Marketing rentabler!</p>
       <Button className="gtm-click" primary="true" href="https://www.linkedin.com/in/sascha-klapetz/" target="_blank">Auf LinkedIn vernetzen</Button>
      </Content>
    </div>
  </div>
)}

Author.propTypes = {
}

Author.defaultProps = {
  
}

export default Author
