import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	Text,
	Grid,
	Icon,
	HList,
} from '../components/styled'

import {
	LinkedinShareButton,
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	LinkedinShareCount,
	FacebookShareCount,
} from 'react-share'

import {Linkedin, Facebook, Twitter} from 'styled-icons/fa-brands/'

import {Envelope as Email} from 'styled-icons/boxicons-solid/'

const ShareList = styled.ul.attrs(props => ({
  className: `list flex nl3 nr3 pl0 ${(props.mt && 'mt4 mb0') || (props.mb && 'mt0 mb4') || 'mv0'} ${(props.center && 'justify-center') || ''}`
}))``

ShareList.Item = styled.li.attrs(props => ({
  className: `ph3`
}))``

const ShareButton = styled.div.attrs(props => ({
  className: `blue pointer`
}))``

const ShareIcon = styled.svg.attrs(props => ({
  className: `w2 h2 v-mid`
}))``

const ShareCount = styled.div.attrs(props => ({
  className: `bg-light-gray gray f7 b tc`
}))``

const Share = (props) => {
	const {url, postNode} = props
	const post = postNode.frontmatter
	const filter = count => (count > 0 ? count : "")
  const renderShareCount = count => (
    <ShareCount>{filter(count)}</ShareCount>
  )
	return (
	<ShareList mt={props.mt} mb={props.mb} center={props.center}>
		<ShareList.Item>
			<ShareButton as={LinkedinShareButton} url={url} title={post.title} description={postNode.excerpt}>
				<ShareIcon as={Linkedin} />
				<LinkedinShareCount url={url}>
					{count => renderShareCount(count)}
				</LinkedinShareCount>
			</ShareButton>
		</ShareList.Item>
		<ShareList.Item>
			<ShareButton as={FacebookShareButton} url={url} quote={postNode.excerpt}>
				<ShareIcon as={Facebook} />
				<FacebookShareCount url={url}>
					{count => renderShareCount(count)}
				</FacebookShareCount>
			</ShareButton>
		</ShareList.Item>
		<ShareList.Item>
			<ShareButton as={TwitterShareButton} url={url} title={post.title}>
				<ShareIcon as={Twitter} />
			</ShareButton>
		</ShareList.Item>
		<ShareList.Item>
			<ShareButton as={EmailShareButton}  url={url} subject={post.title} body={url}>
				<ShareIcon as={Email} />
			</ShareButton>
		</ShareList.Item>
	</ShareList>
)}

Share.propTypes = {

}

Share.defaultProps = {

}

export default Share