import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  Text,
  Image,
  A,
  Figure,
  HList,
  List,
} from '../components/styled'
import Share from '../components/Share'
import Author from '../components/Author'

import urljoin from 'url-join'
import kebabCase from "lodash/kebabCase"

import styled from 'styled-components'

const Tags = styled(List).attrs(props => ({
  className: `${props.className} nl2 nr2`
}))``

const Tag = styled(List.Item).attrs(props => ({
  className: `${props.className} dib ph2`
}))``

const TagLink = styled.a.attrs(props => ({
  className: `${props.className} link dib ba b--moon-gray pa1 gray f6 lh-solid`
}))`
  color: inherit;
`

export default ({ data, location }) => {
  //console.log(data)
  const page = data.markdownRemark
  const site = data.site.siteMetadata
  const url = urljoin(site.siteUrl, site.pathPrefix, location.pathname)
  return (
  <Layout>
    <SEO
      title={page.frontmatter.title}
      page={page}
    />
  	<Container>
  		<Container.Inner narrow className="relative">
        <Content mb><A as={Link} to="/blog/">Blog</A></Content>

        {/*page.frontmatter.tags &&
          <Content full-width mb>
            <Tags>
            {page.frontmatter.tags.map(tag => 
              <Tag className="mb2"><TagLink as={Link} to={`/tags/${kebabCase(tag)}`}>{tag}</TagLink></Tag>
            )}
            </Tags>
          </Content>
        */}
		    <Title as="h1">{page.frontmatter.title}</Title>
        <Content full-width mb>
          von <A colored as={Link} to="/kontakt/">Sascha Klapetz</A>
         <Content full-width mb>{page.frontmatter.date}</Content>
        </Content>
        <Content full-width mb>
          {/*<Share url={url} postNode={page} mb />*/}
          {page.frontmatter.image && 
            <Figure>
              <Image as={Img} fluid={page.frontmatter.image.childImageSharp.fluid} />
              {page.frontmatter.image_source && <Figure.Caption>{page.frontmatter.image_source}</Figure.Caption>}
            </Figure>
          }
        </Content>
		    <Content full-width mb dangerouslySetInnerHTML={{ __html: page.html }} />
        <Content full-width mb center>
          <Title smaller>Beitrag gefallen? Jetzt teilen:</Title>
          <Share center url={url} postNode={page} />
        </Content>
         
        {page.frontmatter.date &&
          <Content className="clip" full-width mb>
            {page.frontmatter.date}
          </Content>
        }
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner narrow>
        <Content full-width>
          <Title smaller>Über den Autor</Title>
          <Author />
        </Content>
		  </Container.Inner>
	  </Container>
    <Container>
      <Container.Inner>
        <Title center>Gemeinsam machen wir Ihr nächstes Online-Projekt erfolgreicher</Title>
        <Content big center>
          <p>Ich unterstütze Sie bei Konzept und Realisierung Ihrer Online-Marketing Kampagnen oder Webseiten, mit über Erfahrung aus 100 Online-Projekten in den letzten 14 Jahren.</p>
          <Button className="gtm-click" as={Link} secondary="true" to="/leistungen/">Leistungen ansehen</Button>
        </Content>
      </Container.Inner>
    </Container>
  </Layout>
)}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        pathPrefix
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        image {
          ...postImage
        }
        image_source
        tags
        date(formatString: "DD.MM.YYYY")
      }
      excerpt
    }
  }    
`
